import * as Types from '../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadCoachingListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LoadCoachingListQuery = { __typename?: 'Query' } & {
  coachingAccountLists: { __typename?: 'CoachingAccountListConnection' } & Pick<
    Types.CoachingAccountListConnection,
    'totalCount' | 'totalPageCount'
  > & {
      nodes: Array<
        { __typename?: 'CoachingAccountList' } & Pick<
          Types.CoachingAccountList,
          | 'id'
          | 'name'
          | 'currency'
          | 'monthlyGoal'
          | 'balance'
          | 'receivedPledges'
          | 'totalPledges'
        > & {
            primaryAppeal?: Types.Maybe<
              { __typename?: 'CoachingAppeal' } & Pick<
                Types.CoachingAppeal,
                | 'active'
                | 'amount'
                | 'amountCurrency'
                | 'id'
                | 'name'
                | 'pledgesAmountNotReceivedNotProcessed'
                | 'pledgesAmountProcessed'
                | 'pledgesAmountTotal'
              >
            >;
            users: { __typename?: 'UserScopedToAccountListConnection' } & {
              nodes: Array<
                { __typename?: 'UserScopedToAccountList' } & Pick<
                  Types.UserScopedToAccountList,
                  'firstName' | 'lastName' | 'id'
                >
              >;
            };
          }
      >;
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        Types.PageInfo,
        'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
      >;
    };
};

export type CoachedPersonFragment = {
  __typename?: 'CoachingAccountList';
} & Pick<
  Types.CoachingAccountList,
  | 'id'
  | 'name'
  | 'currency'
  | 'monthlyGoal'
  | 'balance'
  | 'receivedPledges'
  | 'totalPledges'
> & {
    primaryAppeal?: Types.Maybe<
      { __typename?: 'CoachingAppeal' } & Pick<
        Types.CoachingAppeal,
        | 'active'
        | 'amount'
        | 'amountCurrency'
        | 'id'
        | 'name'
        | 'pledgesAmountNotReceivedNotProcessed'
        | 'pledgesAmountProcessed'
        | 'pledgesAmountTotal'
      >
    >;
    users: { __typename?: 'UserScopedToAccountListConnection' } & {
      nodes: Array<
        { __typename?: 'UserScopedToAccountList' } & Pick<
          Types.UserScopedToAccountList,
          'firstName' | 'lastName' | 'id'
        >
      >;
    };
  };

export const CoachedPersonFragmentDoc = gql`
  fragment CoachedPerson on CoachingAccountList {
    id
    name
    primaryAppeal {
      active
      amount
      amountCurrency
      id
      name
      pledgesAmountNotReceivedNotProcessed
      pledgesAmountProcessed
      pledgesAmountTotal
    }
    users {
      nodes {
        firstName
        lastName
        id
      }
    }
    currency
    monthlyGoal
    balance
    receivedPledges
    totalPledges
  }
`;
export const LoadCoachingListDocument = gql`
  query LoadCoachingList {
    coachingAccountLists(first: 25) {
      nodes {
        ...CoachedPerson
      }
      totalCount
      totalPageCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CoachedPersonFragmentDoc}
`;

/**
 * __useLoadCoachingListQuery__
 *
 * To run a query within a React component, call `useLoadCoachingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCoachingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCoachingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCoachingListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadCoachingListQuery,
    LoadCoachingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadCoachingListQuery, LoadCoachingListQueryVariables>(
    LoadCoachingListDocument,
    options,
  );
}
export function useLoadCoachingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCoachingListQuery,
    LoadCoachingListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCoachingListQuery,
    LoadCoachingListQueryVariables
  >(LoadCoachingListDocument, options);
}
export type LoadCoachingListQueryHookResult = ReturnType<
  typeof useLoadCoachingListQuery
>;
export type LoadCoachingListLazyQueryHookResult = ReturnType<
  typeof useLoadCoachingListLazyQuery
>;
export type LoadCoachingListQueryResult = Apollo.QueryResult<
  LoadCoachingListQuery,
  LoadCoachingListQueryVariables
>;
