import * as Types from '../../src/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDefaultAccountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDefaultAccountQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'User' } & Pick<Types.User, 'id' | 'defaultAccountList'>;
  accountLists: { __typename?: 'AccountListConnection' } & {
    nodes: Array<
      { __typename?: 'AccountList' } & Pick<Types.AccountList, 'id'>
    >;
  };
};

export const GetDefaultAccountDocument = gql`
  query GetDefaultAccount {
    user {
      id
      defaultAccountList
    }
    accountLists(first: 1) {
      nodes {
        id
      }
    }
  }
`;

/**
 * __useGetDefaultAccountQuery__
 *
 * To run a query within a React component, call `useGetDefaultAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDefaultAccountQuery,
    GetDefaultAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDefaultAccountQuery,
    GetDefaultAccountQueryVariables
  >(GetDefaultAccountDocument, options);
}
export function useGetDefaultAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultAccountQuery,
    GetDefaultAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDefaultAccountQuery,
    GetDefaultAccountQueryVariables
  >(GetDefaultAccountDocument, options);
}
export type GetDefaultAccountQueryHookResult = ReturnType<
  typeof useGetDefaultAccountQuery
>;
export type GetDefaultAccountLazyQueryHookResult = ReturnType<
  typeof useGetDefaultAccountLazyQuery
>;
export type GetDefaultAccountQueryResult = Apollo.QueryResult<
  GetDefaultAccountQuery,
  GetDefaultAccountQueryVariables
>;
