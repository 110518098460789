export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Filter: [
      'CheckboxFilter',
      'DaterangeFilter',
      'MultiselectFilter',
      'NumericRangeFilter',
      'RadioFilter',
      'TextFilter',
    ],
  },
};
export default result;
