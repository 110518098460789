import * as Types from '../../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcknowledgeUserNotificationMutationVariables = Types.Exact<{
  notificationId: Types.Scalars['ID']['input'];
}>;

export type AcknowledgeUserNotificationMutation = {
  __typename?: 'Mutation';
} & {
  acknowledgeUserNotification?: Types.Maybe<
    { __typename?: 'AcknowledgeUserNotificationMutationPayload' } & {
      notification: { __typename?: 'UserNotification' } & Pick<
        Types.UserNotification,
        'id' | 'read'
      >;
    }
  >;
};

export const AcknowledgeUserNotificationDocument = gql`
  mutation AcknowledgeUserNotification($notificationId: ID!) {
    acknowledgeUserNotification(input: { notificationId: $notificationId }) {
      notification {
        id
        read
      }
    }
  }
`;
export type AcknowledgeUserNotificationMutationFn = Apollo.MutationFunction<
  AcknowledgeUserNotificationMutation,
  AcknowledgeUserNotificationMutationVariables
>;

/**
 * __useAcknowledgeUserNotificationMutation__
 *
 * To run a mutation, you first call `useAcknowledgeUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeUserNotificationMutation, { data, loading, error }] = useAcknowledgeUserNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useAcknowledgeUserNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcknowledgeUserNotificationMutation,
    AcknowledgeUserNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcknowledgeUserNotificationMutation,
    AcknowledgeUserNotificationMutationVariables
  >(AcknowledgeUserNotificationDocument, options);
}
export type AcknowledgeUserNotificationMutationHookResult = ReturnType<
  typeof useAcknowledgeUserNotificationMutation
>;
export type AcknowledgeUserNotificationMutationResult =
  Apollo.MutationResult<AcknowledgeUserNotificationMutation>;
export type AcknowledgeUserNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    AcknowledgeUserNotificationMutation,
    AcknowledgeUserNotificationMutationVariables
  >;
