import * as Types from '../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserOptionQueryVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
}>;

export type UserOptionQuery = { __typename?: 'Query' } & {
  userOption?: Types.Maybe<
    { __typename?: 'Option' } & Pick<Types.Option, 'key' | 'value'>
  >;
};

export type UpdateUserOptionMutationVariables = Types.Exact<{
  key: Types.Scalars['String']['input'];
  value: Types.Scalars['String']['input'];
}>;

export type UpdateUserOptionMutation = { __typename?: 'Mutation' } & {
  createOrUpdateUserOption?: Types.Maybe<
    { __typename?: 'CreateOrUpdateOptionMutationPayload' } & {
      option: { __typename?: 'Option' } & Pick<Types.Option, 'key' | 'value'>;
    }
  >;
};

export const UserOptionDocument = gql`
  query UserOption($key: String!) {
    userOption(key: $key) {
      key
      value
    }
  }
`;

/**
 * __useUserOptionQuery__
 *
 * To run a query within a React component, call `useUserOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOptionQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUserOptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserOptionQuery,
    UserOptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserOptionQuery, UserOptionQueryVariables>(
    UserOptionDocument,
    options,
  );
}
export function useUserOptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserOptionQuery,
    UserOptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserOptionQuery, UserOptionQueryVariables>(
    UserOptionDocument,
    options,
  );
}
export type UserOptionQueryHookResult = ReturnType<typeof useUserOptionQuery>;
export type UserOptionLazyQueryHookResult = ReturnType<
  typeof useUserOptionLazyQuery
>;
export type UserOptionQueryResult = Apollo.QueryResult<
  UserOptionQuery,
  UserOptionQueryVariables
>;
export const UpdateUserOptionDocument = gql`
  mutation UpdateUserOption($key: String!, $value: String!) {
    createOrUpdateUserOption(input: { key: $key, value: $value }) {
      option {
        key
        value
      }
    }
  }
`;
export type UpdateUserOptionMutationFn = Apollo.MutationFunction<
  UpdateUserOptionMutation,
  UpdateUserOptionMutationVariables
>;

/**
 * __useUpdateUserOptionMutation__
 *
 * To run a mutation, you first call `useUpdateUserOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOptionMutation, { data, loading, error }] = useUpdateUserOptionMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateUserOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOptionMutation,
    UpdateUserOptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserOptionMutation,
    UpdateUserOptionMutationVariables
  >(UpdateUserOptionDocument, options);
}
export type UpdateUserOptionMutationHookResult = ReturnType<
  typeof useUpdateUserOptionMutation
>;
export type UpdateUserOptionMutationResult =
  Apollo.MutationResult<UpdateUserOptionMutation>;
export type UpdateUserOptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOptionMutation,
  UpdateUserOptionMutationVariables
>;
