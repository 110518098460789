import * as Types from '../../../../../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcknowledgeAllUserNotificationsMutationVariables = Types.Exact<{
  accountListId: Types.Scalars['ID']['input'];
}>;

export type AcknowledgeAllUserNotificationsMutation = {
  __typename?: 'Mutation';
} & {
  acknowledgeAllUserNotifications?: Types.Maybe<
    { __typename?: 'AcknowledgeAllUserNotificationsMutationPayload' } & Pick<
      Types.AcknowledgeAllUserNotificationsMutationPayload,
      'notificationIds'
    >
  >;
};

export const AcknowledgeAllUserNotificationsDocument = gql`
  mutation AcknowledgeAllUserNotifications($accountListId: ID!) {
    acknowledgeAllUserNotifications(input: { accountListId: $accountListId }) {
      notificationIds
    }
  }
`;
export type AcknowledgeAllUserNotificationsMutationFn = Apollo.MutationFunction<
  AcknowledgeAllUserNotificationsMutation,
  AcknowledgeAllUserNotificationsMutationVariables
>;

/**
 * __useAcknowledgeAllUserNotificationsMutation__
 *
 * To run a mutation, you first call `useAcknowledgeAllUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeAllUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeAllUserNotificationsMutation, { data, loading, error }] = useAcknowledgeAllUserNotificationsMutation({
 *   variables: {
 *      accountListId: // value for 'accountListId'
 *   },
 * });
 */
export function useAcknowledgeAllUserNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcknowledgeAllUserNotificationsMutation,
    AcknowledgeAllUserNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcknowledgeAllUserNotificationsMutation,
    AcknowledgeAllUserNotificationsMutationVariables
  >(AcknowledgeAllUserNotificationsDocument, options);
}
export type AcknowledgeAllUserNotificationsMutationHookResult = ReturnType<
  typeof useAcknowledgeAllUserNotificationsMutation
>;
export type AcknowledgeAllUserNotificationsMutationResult =
  Apollo.MutationResult<AcknowledgeAllUserNotificationsMutation>;
export type AcknowledgeAllUserNotificationsMutationOptions =
  Apollo.BaseMutationOptions<
    AcknowledgeAllUserNotificationsMutation,
    AcknowledgeAllUserNotificationsMutationVariables
  >;
