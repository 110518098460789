import * as Types from '../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupStageQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SetupStageQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'User' } & Pick<
    Types.User,
    'id' | 'defaultAccountList' | 'setup'
  >;
  userOption?: Types.Maybe<
    { __typename?: 'Option' } & Pick<Types.Option, 'id' | 'key' | 'value'>
  >;
};

export const SetupStageDocument = gql`
  query SetupStage {
    user {
      id
      defaultAccountList
      setup
    }
    userOption(key: "setup_position") {
      id
      key
      value
    }
  }
`;

/**
 * __useSetupStageQuery__
 *
 * To run a query within a React component, call `useSetupStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupStageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetupStageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SetupStageQuery,
    SetupStageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SetupStageQuery, SetupStageQueryVariables>(
    SetupStageDocument,
    options,
  );
}
export function useSetupStageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SetupStageQuery,
    SetupStageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SetupStageQuery, SetupStageQueryVariables>(
    SetupStageDocument,
    options,
  );
}
export type SetupStageQueryHookResult = ReturnType<typeof useSetupStageQuery>;
export type SetupStageLazyQueryHookResult = ReturnType<
  typeof useSetupStageLazyQuery
>;
export type SetupStageQueryResult = Apollo.QueryResult<
  SetupStageQuery,
  SetupStageQueryVariables
>;
