import * as Types from '../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnnouncementsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AnnouncementsQuery = { __typename?: 'Query' } & {
  announcements: { __typename?: 'AnnouncementConnection' } & {
    nodes: Array<
      { __typename?: 'Announcement' } & Pick<
        Types.Announcement,
        'id' | 'displayMethod' | 'body' | 'title' | 'style' | 'imageUrl'
      > & {
          actions: Array<
            { __typename?: 'Action' } & Pick<
              Types.Action,
              'id' | 'label' | 'action' | 'style' | 'args'
            >
          >;
        }
    >;
  };
};

export type AnnouncementFragment = { __typename?: 'Announcement' } & Pick<
  Types.Announcement,
  'id' | 'displayMethod' | 'body' | 'title' | 'style' | 'imageUrl'
> & {
    actions: Array<
      { __typename?: 'Action' } & Pick<
        Types.Action,
        'id' | 'label' | 'action' | 'style' | 'args'
      >
    >;
  };

export type ActionFragment = { __typename?: 'Action' } & Pick<
  Types.Action,
  'id' | 'label' | 'action' | 'style' | 'args'
>;

export type AcknowledgeAnnouncementMutationVariables = Types.Exact<{
  input: Types.AcknowledgeAnnouncementMutationInput;
}>;

export type AcknowledgeAnnouncementMutation = { __typename?: 'Mutation' } & {
  acknowledgeAnnouncement?: Types.Maybe<
    { __typename?: 'AcknowledgeAnnouncementMutationPayload' } & {
      announcement: { __typename?: 'Announcement' } & Pick<
        Types.Announcement,
        'id'
      >;
    }
  >;
};

export const ActionFragmentDoc = gql`
  fragment Action on Action {
    id
    label
    action
    style
    args
  }
`;
export const AnnouncementFragmentDoc = gql`
  fragment Announcement on Announcement {
    actions {
      ...Action
    }
    id
    displayMethod
    body
    title
    style
    imageUrl
  }
  ${ActionFragmentDoc}
`;
export const AnnouncementsDocument = gql`
  query Announcements {
    announcements(first: 1) {
      nodes {
        ...Announcement
      }
    }
  }
  ${AnnouncementFragmentDoc}
`;

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnnouncementsQuery,
    AnnouncementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(
    AnnouncementsDocument,
    options,
  );
}
export function useAnnouncementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnnouncementsQuery,
    AnnouncementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(
    AnnouncementsDocument,
    options,
  );
}
export type AnnouncementsQueryHookResult = ReturnType<
  typeof useAnnouncementsQuery
>;
export type AnnouncementsLazyQueryHookResult = ReturnType<
  typeof useAnnouncementsLazyQuery
>;
export type AnnouncementsQueryResult = Apollo.QueryResult<
  AnnouncementsQuery,
  AnnouncementsQueryVariables
>;
export const AcknowledgeAnnouncementDocument = gql`
  mutation AcknowledgeAnnouncement(
    $input: AcknowledgeAnnouncementMutationInput!
  ) {
    acknowledgeAnnouncement(input: $input) {
      announcement {
        id
      }
    }
  }
`;
export type AcknowledgeAnnouncementMutationFn = Apollo.MutationFunction<
  AcknowledgeAnnouncementMutation,
  AcknowledgeAnnouncementMutationVariables
>;

/**
 * __useAcknowledgeAnnouncementMutation__
 *
 * To run a mutation, you first call `useAcknowledgeAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeAnnouncementMutation, { data, loading, error }] = useAcknowledgeAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcknowledgeAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcknowledgeAnnouncementMutation,
    AcknowledgeAnnouncementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcknowledgeAnnouncementMutation,
    AcknowledgeAnnouncementMutationVariables
  >(AcknowledgeAnnouncementDocument, options);
}
export type AcknowledgeAnnouncementMutationHookResult = ReturnType<
  typeof useAcknowledgeAnnouncementMutation
>;
export type AcknowledgeAnnouncementMutationResult =
  Apollo.MutationResult<AcknowledgeAnnouncementMutation>;
export type AcknowledgeAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  AcknowledgeAnnouncementMutation,
  AcknowledgeAnnouncementMutationVariables
>;
