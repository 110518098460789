import * as Types from '../../graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadConstantsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LoadConstantsQuery = { __typename?: 'Query' } & {
  constant: { __typename?: 'Constant' } & {
    activities?: Types.Maybe<
      Array<
        { __typename?: 'ActivitiesConstant' } & Pick<
          Types.ActivitiesConstant,
          'id' | 'value' | 'name' | 'action'
        >
      >
    >;
    displayResults?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    languages?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    locales?: Types.Maybe<
      Array<
        { __typename?: 'Locale' } & Pick<
          Types.Locale,
          'englishName' | 'nativeName' | 'shortName'
        >
      >
    >;
    likelyToGive?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    locations?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id'>>
    >;
    newsletter?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    pledgeCurrency?: Types.Maybe<
      Array<
        { __typename?: 'Currency' } & Pick<
          Types.Currency,
          | 'id'
          | 'key'
          | 'codeSymbolString'
          | 'name'
          | 'code'
          | 'value'
          | 'symbol'
        >
      >
    >;
    pledgeFrequency?: Types.Maybe<
      Array<
        { __typename?: 'IdKeyValue' } & Pick<
          Types.IdKeyValue,
          'id' | 'key' | 'value'
        >
      >
    >;
    pledgesReceived?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    preferredContactMethods?: Types.Maybe<
      Array<
        { __typename?: 'IdKeyValue' } & Pick<Types.IdKeyValue, 'id' | 'value'>
      >
    >;
    sendAppeals?: Types.Maybe<
      Array<{ __typename?: 'IdValue' } & Pick<Types.IdValue, 'id' | 'value'>>
    >;
    status?: Types.Maybe<
      Array<
        { __typename?: 'StatusesConstant' } & Pick<
          Types.StatusesConstant,
          'id' | 'value'
        >
      >
    >;
    times?: Types.Maybe<
      Array<{ __typename?: 'Time' } & Pick<Types.Time, 'key' | 'value'>>
    >;
    phases?: Types.Maybe<
      Array<
        { __typename?: 'Phase' } & Pick<
          Types.Phase,
          'id' | 'name' | 'contactStatuses' | 'tasks'
        > & {
            results?: Types.Maybe<
              { __typename?: 'Result' } & {
                resultOptions?: Types.Maybe<
                  Array<
                    { __typename?: 'ResultOption' } & Pick<
                      Types.ResultOption,
                      | 'name'
                      | 'value'
                      | 'suggestedContactStatus'
                      | 'suggestedNextActions'
                    > & {
                        dbResult?: Types.Maybe<
                          Array<
                            { __typename?: 'TaskResultPair' } & Pick<
                              Types.TaskResultPair,
                              'task' | 'result'
                            >
                          >
                        >;
                      }
                  >
                >;
                tags?: Types.Maybe<
                  Array<
                    { __typename?: 'IdValue' } & Pick<
                      Types.IdValue,
                      'value' | 'id'
                    >
                  >
                >;
              }
            >;
          }
      >
    >;
  };
  user: { __typename?: 'User' } & Pick<Types.User, 'id'> & {
      preferences?: Types.Maybe<
        { __typename?: 'Preference' } & Pick<Types.Preference, 'id' | 'locale'>
      >;
    };
};

export const LoadConstantsDocument = gql`
  query LoadConstants {
    constant {
      activities {
        id
        value
        name
        action
      }
      displayResults {
        id
        value
      }
      languages {
        id
        value
      }
      locales {
        englishName
        nativeName
        shortName
      }
      likelyToGive {
        id
        value
      }
      locations {
        id
      }
      newsletter {
        id
        value
      }
      pledgeCurrency {
        id
        key
        codeSymbolString
        name
        code
        value
        symbol
      }
      pledgeFrequency {
        id
        key
        value
      }
      pledgesReceived {
        id
        value
      }
      preferredContactMethods {
        id
        value
      }
      sendAppeals {
        id
        value
      }
      status {
        id
        value
      }
      times {
        key
        value
      }
      phases {
        id
        name
        results {
          resultOptions {
            dbResult {
              task
              result
            }
            name
            value
            suggestedContactStatus
            suggestedNextActions
          }
          tags {
            value
            id
          }
        }
        contactStatuses
        tasks
      }
    }
    user {
      id
      preferences {
        id
        locale
      }
    }
  }
`;

/**
 * __useLoadConstantsQuery__
 *
 * To run a query within a React component, call `useLoadConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadConstantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadConstantsQuery,
    LoadConstantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadConstantsQuery, LoadConstantsQueryVariables>(
    LoadConstantsDocument,
    options,
  );
}
export function useLoadConstantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadConstantsQuery,
    LoadConstantsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadConstantsQuery, LoadConstantsQueryVariables>(
    LoadConstantsDocument,
    options,
  );
}
export type LoadConstantsQueryHookResult = ReturnType<
  typeof useLoadConstantsQuery
>;
export type LoadConstantsLazyQueryHookResult = ReturnType<
  typeof useLoadConstantsLazyQuery
>;
export type LoadConstantsQueryResult = Apollo.QueryResult<
  LoadConstantsQuery,
  LoadConstantsQueryVariables
>;
